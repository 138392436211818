var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BPagination",
    _vm._g(
      _vm._b(
        {
          ref: "pagination",
          attrs: { "first-number": "", "last-number": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "prev-text",
                fn: function() {
                  return [_c("BButton", [_vm._v("« Prev")])]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [_c("BButton", [_vm._v("Next »")])]
                },
                proxy: true
              },
              {
                key: "page",
                fn: function(ref) {
                  var page = ref.page
                  var active = ref.active
                  return [
                    _c(
                      "BButton",
                      { class: { active: active }, attrs: { active: active } },
                      [_vm._v(" " + _vm._s(page) + " ")]
                    )
                  ]
                }
              },
              _vm._l(_vm.$scopedSlots, function(_, slot) {
                return {
                  key: slot,
                  fn: function(scope) {
                    return [_vm._t(slot, null, null, scope)]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        "BPagination",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }