var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "per-page" },
    [
      _c("div", { staticClass: "per-page--label" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c(
        "BDropdown",
        { attrs: { text: "" + _vm.value } },
        _vm._l(_vm.options, function(option) {
          return _c(
            "DropdownItem",
            {
              key: option,
              attrs: { selected: option === _vm.value },
              nativeOn: {
                click: function($event) {
                  return _vm.$emit("change", option)
                }
              }
            },
            [_vm._v(" " + _vm._s(option) + " ")]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }