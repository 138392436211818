var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    ref: "ctrl",
    attrs: { fetch: _vm.fetch, params: _vm.fetchParams },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var data = ref.data
            return [
              !_vm.noPagination
                ? _c(
                    "PaginationController",
                    {
                      attrs: {
                        "current-page": _vm.currentPage,
                        name: _vm.name,
                        "per-page": _vm.perPageValue,
                        "total-rows": data.total
                      },
                      on: {
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:perPage": function($event) {
                          _vm.perPageValue = $event
                        },
                        "update:per-page": function($event) {
                          _vm.perPageValue = $event
                        }
                      }
                    },
                    [
                      _vm._t("default", null, {
                        items: _vm.freeze(data.data),
                        total: _vm.emitStatus(data.total)
                      })
                    ],
                    2
                  )
                : _vm._t("default", null, {
                    items: _vm.freeze(data.data),
                    total: _vm.emitStatus(data.total)
                  })
            ]
          }
        },
        {
          key: "no-data",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }