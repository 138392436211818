var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pwp" },
    [
      _c("AppPagination", {
        attrs: {
          value: _vm.currentPage,
          "per-page": _vm.perPage,
          "total-rows": _vm.totalRows
        },
        on: {
          input: function($event) {
            return _vm.$emit("update:current-page", $event)
          }
        }
      }),
      _c("PerPage", {
        attrs: { name: _vm.name, value: _vm.perPage },
        on: {
          change: function($event) {
            return _vm.$emit("update:per-page", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }