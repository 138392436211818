var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t(
        "top-pagination",
        function() {
          return [
            _c("PaginationWithPaging", {
              attrs: {
                "current-page": _vm.currentPageValue,
                name: _vm.name,
                "per-page": _vm.perPageValue,
                "total-rows": _vm.totalRows
              },
              on: {
                "update:currentPage": function($event) {
                  _vm.currentPageValue = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPageValue = $event
                },
                "update:perPage": function($event) {
                  _vm.perPageValue = $event
                },
                "update:per-page": function($event) {
                  _vm.perPageValue = $event
                }
              }
            })
          ]
        },
        null,
        _vm.context
      ),
      _vm._t(
        "default",
        function() {
          return [
            _c(
              _vm.childComponent,
              _vm._g(
                _vm._b(
                  { tag: "component" },
                  "component",
                  _vm.childAttributes,
                  false
                ),
                _vm.childListeners
              )
            )
          ]
        },
        null,
        _vm.context
      ),
      _vm._t(
        "bottom-pagination",
        function() {
          return [
            _c("PaginationWithPaging", {
              attrs: {
                "current-page": _vm.currentPageValue,
                name: _vm.name,
                "per-page": _vm.perPageValue,
                "total-rows": _vm.totalRows
              },
              on: {
                "update:currentPage": function($event) {
                  _vm.currentPageValue = $event
                },
                "update:current-page": function($event) {
                  _vm.currentPageValue = $event
                },
                "update:perPage": function($event) {
                  _vm.perPageValue = $event
                },
                "update:per-page": function($event) {
                  _vm.perPageValue = $event
                }
              }
            })
          ]
        },
        null,
        _vm.context
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }